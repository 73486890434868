<template>
  <div class="app-container-empty"></div>
</template>

<script>
import router from "@/router";
import { envInfo } from "@/constants/envInfo";

//首页跳转壳子
export default {
  name: "Home",
  created() {
    // 重定向的逻辑不在这里做，这里执行的时候菜单还没初始化完
    // let currentTenantId = this.$store.getters.currentTenant;
    // if (!currentTenantId) {
    //   // 如果当前用户没有租户，跳转设置中心
    //   router.replace({
    //     path: envInfo.sitemap.personal.href,
    //   });
    // } else if (currentTenantId == -1) {
    //   // 跳转平台首页
    //   router.replace({
    //     path: envInfo.sitemap.home.platformHome,
    //   });
    // } else {
    //   console.log("xxx-2", this.$store.getters.tenantHomePage);
    //   // 跳转租户首页
    //   router.replace({
    //     path: this.$store.getters.tenantHomePage,
    //   });
    // }
  },
};
</script>
